<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-row>
        <el-col :span="8">
          <el-form-item label="诊断编码名称：">
            <el-input
              v-model.trim="formInline.orgName"
              placeholder="请输入诊断编码名称"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-button type="primary" @click="queryData()"> 查询 </el-button>
            <el-button type="primary" @click="clearData()"> 重置 </el-button>
            <el-button
              v-if="$checkPermission('ADDDIAGNOSTIC', $route)"
              type="primary"
              @click="dataOperation(null, 1)"
            >
              新增
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="orgCode" label="诊断编码" align="center" />
      <el-table-column prop="orgName" label="诊断名称" align="center" />
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            v-if="$checkPermission('EDITDIAGNOSTIC', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 0)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :title="titleType + '诊断类型'"
      :visible.sync="dialogVisible"
      width="35%"
    >
      <el-form
        v-if="dialogVisible"
        ref="form1"
        label-position="right"
        :model="form1"
        :rules="rules"
        label-width="160px"
        @submit.native.prevent
      >
        <!-- <h3 style="margin-bottom:20px;text-align: center;">确定核销当前预约单吗？</h3> -->
        <el-form-item label="诊断编码:" prop="orgCode">
          <el-input
            v-model="form1.orgCode"
            placeholder="请输入诊断编码"
            style="width: 80%"
            :disabled="titleType === '修改'"
            maxlength="30"
            @keyup.enter.native="saveDiagnostic()"
          />
        </el-form-item>
        <el-form-item label="诊断名称:" prop="orgName">
          <el-input
            v-model="form1.orgName"
            placeholder="诊断名称"
            style="width: 80%"
            maxlength="200"
            @keyup.enter.native="saveDiagnostic()"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveDiagnostic()">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { codeRule } from '@/utils/verificationRule.js';
export default {
  name: 'ServiceManageList',
  data() {
    return {
      titleType: '',
      editId: '', // 编辑诊断的id
      loading: false, // 给列表加上loading效果
      dialogVisible: false, // 控制新增、编辑弹框显示与否
      formInline: {
        orgName: '',
      },
      form1: {
        orgName: '',
        orgCode: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      rules: {
        orgCode: [{ required: true, validator: codeRule, trigger: 'change' }],
        orgName: [
          { required: true, message: '请输入诊断编码名称', trigger: 'change' },
        ],
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {},
  mounted() {
    this.getOrganList();
  },
  methods: {
    ...mapActions('basicDictionary', ['organPageQuery', 'organEdit']),
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getOrganList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getOrganList();
    },
    // 重置
    clearData() {
      this.formInline = {
        // 查询条件
        orgName: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getOrganList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getOrganList();
    },
    // 分页查询用户套餐列表
    getOrganList() {
      this.loading = true;
      const params = {
        ...this.formInline,
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      this.organPageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      if (type === 0) {
        this.form1 = {
          orgName: item.orgName,
          orgCode: item.orgCode,
        };
        this.editId = item.id;
        this.titleType = '修改';
      } else {
        this.form1 = {
          orgName: '',
          orgCode: '',
        };
        this.titleType = '新增';
      }
      this.dialogVisible = true;
    },
    // 新增诊断编码
    saveDiagnostic() {
      this.$refs['form1'].validate((valid) => {
        if (valid) {
          const param = {
            ...JSON.parse(JSON.stringify(this.form1)),
          };
          if (this.titleType === '修改') {
            param.id = this.editId;
          }
          console.log(param);
          this.organEdit(param).then((res) => {
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message:
                  this.titleType === '修改'
                    ? '修改诊断编码成功'
                    : '新增诊断编码成功',
              });
              this.dialogVisible = false;
              this.getOrganList();
            }
            this.$refs['form1'].resetFields();
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
